import Popup from "./Popup";
import React from "react";
import { useFormAndValidation } from "../../hooks/useFormAndValidation";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { patterns } from "../../consts/patterns";
import { datesTypes } from "../../consts/consts";

function EditPopup({ onRenderLoading, date, onClose, onSubmit, title, name, linkDescription, errorStatus, errorText }) {
    
    const { values, handleChange, errors, isValid, resetForm } =
        useFormAndValidation();

    const initialCheckboxes = Object.keys(datesTypes).reduce((accumulator, value) => {
        return {...accumulator, [value]: false};
      }, {});
    
    const [checkboxes, setCheckboxes] = React.useState(initialCheckboxes);
    const [countSelected, setCountSelected] = React.useState(0);

    const currentUser = React.useContext(CurrentUserContext);

    function handleCheckbox (e) {
        checkboxes[e.target.id] ? setCountSelected(countSelected-1) : setCountSelected(countSelected+1);
        setCheckboxes({ ...checkboxes, [e.target.id]:!checkboxes[e.target.id]} );
    }
    
    function handleSubmit(e) {
        e.preventDefault();
        const types = []; 
        Object.keys(checkboxes).forEach((type) => {
            if (checkboxes[type]) {
                types.push(datesTypes[type])
            }
        })
        onSubmit({...values, _id: date._id, types: types, author:currentUser.name});
    }

    function handleCLose() {
        resetForm(
            { name: date.name, address: date.address, price: date.price, link: date.link, image: date.image, description: date.description },
            { name: "", address: "", price: "", link: "", image: "", description: "" },
            { name: true, address: true, price: true, link: true, image: true, description: true }
        );
        setCheckboxes({})
        setCountSelected(0);
        onClose();
    }

    React.useEffect(() => {
        resetForm(
            { name: date.name, address: date.address, price: date.price, link: date.link, image: date.image, description: date.description },
            { name: "", address: "", price: "", link: "", image: "", description: "" },
            { name: true, address: true, price: true, link: true, image: true, description: true }
        );
        if (date._id) {
            const obj = {};
            var i = 0;
            Object.keys(datesTypes).forEach((type => {                
                if (date.types.includes(datesTypes[type])) {
                    obj[type] = true;
                    i++;                    
                } else {
                    obj[type] = false 
                };
            }))
            setCheckboxes(obj);
            setCountSelected(i)
        }        
    }, [date]);

    return (
        <Popup
            title={title}
            name={name}
            buttonText={onRenderLoading ? "Сохранение..." : "Сохранить"}
            isOpened={date._id ? true : false}
            onClose={handleCLose}
            onSubmit={handleSubmit}
            buttonState={
                values.name &&
                values.description &&
                isValid.name &&
                isValid.description &&
                isValid.address &&
                isValid.price &&
                isValid.link &&
                isValid.image &&
                (countSelected > 0) &&
                (countSelected < 5)
            }
            linkDescription={linkDescription}
            errorStatus={errorStatus}
            errorText={errorText}
        >
            <div className="popup__inputs-container">
                <div className="popup__input-container">
                    <p className="popup__input-name">Название события</p>
                    <input
                        type="text"
                        className={
                            isValid.name
                                ? "popup__input"
                                : "popup__input popup__input_active"
                            }
                        id={`popup-${name}-name`}
                        name="name"
                        value={values.name ? values.name : ""}
                        required
                        minLength="2"
                        maxLength="15"
                        pattern={patterns.namePattern}
                        placeholder="Введите название события"
                        onChange={handleChange}
                        readOnly={!onRenderLoading ? false : true}
                    />
                    <span
                        className="popup__input-error"
                        id={`popup-${name}-name-input-error`}
                    >
                        {errors.name}
                    </span>
                </div>
                <div className="popup__input-container">   
                    <p className="popup__input-name">Адрес</p>
                    <input
                        type="text"
                        className={
                            isValid.address
                                ? "popup__input"
                                : "popup__input popup__input_active"
                            }
                        id={`popup-${name}-address`}
                        name="address"
                        value={values.address ? values.address : ""}
                        placeholder="Введите адрес, если хотите"
                        onChange={handleChange}
                        readOnly={!onRenderLoading ? false : true}
                    />
                    <span
                        className="popup__input-error"
                        id={`popup-${name}-address-input-error`}
                    >
                        {errors.address}
                    </span>
                </div>
                <div className="popup__input-container">     
                    <p className="popup__input-name">Стоимость</p>
                    <input
                        type="text"
                        className={
                            isValid.price
                                ? "popup__input"
                                : "popup__input popup__input_active"
                            }
                        id={`popup-${name}-price`}
                        name="price"
                        value={values.price ? values.price : ""}
                        minLength="2"
                        maxLength="15"
                        placeholder="Введите стоимость, если хотите"
                        onChange={handleChange}
                        readOnly={!onRenderLoading ? false : true}
                    />
                    <span
                        className="popup__input-error"
                        id={`popup-${name}-price-input-error`}
                    >
                        {errors.price}
                    </span>
                </div>
                <div className="popup__input-container">    
                    <p className="popup__input-name">Ссылка</p>
                    <input
                        type="url"
                        className={
                            isValid.link
                                ? "popup__input"
                                : "popup__input popup__input_active"
                            }
                        id={`popup-${name}-link`}
                        name="link"
                        value={values.link ? values.link : ""}
                        placeholder="Введите ссылку на событие или сайт, если хотите"
                        onChange={handleChange}
                        readOnly={!onRenderLoading ? false : true}
                    />
                    <span
                        className="popup__input-error"
                        id={`popup-${name}-link-input-error`}
                    >
                        {errors.link}
                    </span>
                </div>
                <div className="popup__input-container">    
                <p className="popup__input-name">Изображение</p>
                    <input
                        type="url"
                        className={
                            isValid.image
                                ? "popup__input"
                                : "popup__input popup__input_active"
                            }
                        id={`popup-${name}-image`}
                        name="image"
                        value={values.image ? values.image : ""}
                        placeholder="Введите ссылку на изображние, если хотите"
                        onChange={handleChange}
                        readOnly={!onRenderLoading ? false : true}
                    />
                    <span
                        className="popup__input-error"
                        id={`popup-${name}-image-input-error`}
                    >
                        {errors.image}
                    </span>
                </div>
                <div className="popup__input-container"> 
                    <p className="popup__input-name">Описание</p>
                    <input
                        type="text"
                        className={
                            isValid.description
                                ? "popup__input"
                                : "popup__input popup__input_active"
                            }
                        id={`popup-${name}-description`}
                        name="description"
                        value={values.description ? values.description : ""}
                        placeholder="Введите описание"
                        onChange={handleChange}
                        readOnly={!onRenderLoading ? false : true}
                    />
                    <span
                        className="popup__input-error"
                        id={`popup-${name}-description-input-error`}
                    >
                        {errors.description}
                    </span>
                </div>
            </div>   
            <p className="popup__input-name">Ти события (выберите до 4)</p>
            <div className="popup__input-checkboxes">
                {Object.keys(datesTypes).map((date, i) => 
                    <div className="popup__input-checkbox-container">
                        <button className={ checkboxes[date] ? "popup__input-checkbox" : "popup__input-checkbox popup__input-checkbox_off"} type="button" id={date} onClick={handleCheckbox}></button>
                        <p className={ checkboxes[date] ? "popup__input-checkbox-name" : "popup__input-checkbox-name popup__input-checkbox-name_off"}>{datesTypes[date]}</p>         
                    </div>    
                )}
            </div>
            <span
                className="popup__input-error"
                id={`popup-${name}-types-error`}
            >
                {((countSelected === 0) || (countSelected > 4)) && "Укажите от одного, до четырех типов"}
            </span>
        </Popup>
    );
}

export default EditPopup;
