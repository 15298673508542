import React from "react";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import Header from "../Header/Header";
import Dates from "../Dates/Dates";
import Main from "../Main/Main";
import Name from "../Name/Name";
import Footer from "../Footer/Footer";
import Sign from "../Sign/Sign";
import SearchForm from "../SearchForm/SearchForm";
import SortForm from "../SortForm/SortForm";
import SurePopup from "../Popup/SurePopup";
import EditPopup from "../Popup/EditPopup";
import Preloader from "../Preloader/Preloader"
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import UnprotectedRoute from "../UnprotectedRoute/UnprotectedRoute";
import { mainApi } from "../../utils/MainApi";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { useDatesFilter } from "../../hooks/useDatesFilter";
import sorryImage from "../../images/ups2.jpg";

function App() {
    const [isRenderLoading, setIsRenderLoading] = React.useState(false);
    const {
        dates,
        setDates, 
        checkboxes,
        setCheckboxes,
        filteredDates,
        filterName,
        setFilterName,
        currentUser, 
        setCurrentUser,
        sortType, 
        setSortType,
    } = useDatesFilter();
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [initialPath, setInitialPath] = React.useState("/");
    const [errorSignText, setErrorSignText] = React.useState("");
    const [errorSignStatus, setErrorSignStatus] = React.useState(false);
    const [errorPopupText, setErrorPopupText] = React.useState("");
    const [errorPopupStatus, setErrorPopupStatus] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");
    const [dateToDelete, setDateToDelete] = React.useState({ _id: "" });
    const [dateToDislike, setDateToDislike] = React.useState({ _id: "" });
    const [dateToUpdate, setDateToUpdate] = React.useState({ _id: "" });
    const [dateToCreate, setDateToCreate] = React.useState({ _id: "" });
    const history = useHistory();

    const closeAllPopups = () => {
        setDateToDelete({ _id: "" });
        setDateToDislike({ _id: "" });
        setDateToUpdate({ _id: "" });
        setDateToCreate({ _id: "" });
        setErrorPopupStatus(false);
        setErrorPopupText("");
    };
    
    const handleSignIn = (password, email) => {
        setIsRenderLoading(true);
        mainApi
            .signIn(password, email)
            .then((data) => {
                setErrorSignStatus(false);
                setErrorSignText("Вход успешно выполнен")
                localStorage.setItem("token", data.token);                
                setIsLoggedIn(true);
                history.push("/movies");
            })
            .catch((err) => {
                console.log(err);
                setErrorSignStatus(true);
                setErrorSignText(err);
            })
            .finally(() => setIsRenderLoading(false));
    };

    const handleSignUp = (password, email, name, code) => {
        setIsRenderLoading(true);
        mainApi
            .signUp(password, email, name, code)
            .then(() => {
                setErrorSignStatus(false);
                setErrorSignText("Регистрация успешно завершена")
            })
            .then(() => {
                handleSignIn(password, email);
            })
            .catch((err) => {
                console.log(err);
                setErrorSignStatus(true);
                setErrorSignText(err);
            })
            .finally(() => setIsRenderLoading(false));
    };

    const handleSignOut = () => {
        setIsLoggedIn(false);
        setCurrentUser({name: "", email: ""});
        setErrorText("");
        closeAllPopups();
        localStorage.removeItem("token");
        history.push("/");
    };

    const handleSignCheck = () => {
        mainApi
            .getUserInfo(localStorage.getItem("token"))
            .then(() => {
                setIsLoggedIn(true);
                history.push(initialPath);
            })
            .catch((err) => {
                console.log(err);
                setIsLoggedIn(false);
                history.push("/dates");
            });
    };

    const handleUpdateUser = (password, email, name) => {
        setIsRenderLoading(true);
        if (password) { 
            mainApi.setUserInfo(email, name, localStorage.getItem("token"), password)
                .then((values) => {
                    setCurrentUser(values);
                    setErrorSignStatus(false);
                    setErrorSignText("Обновление данных успешно выполнено");
                })
                .catch((err) => {
                    setErrorSignStatus(true);
                    setErrorSignText(err);
                })
                .finally(() => setIsRenderLoading(false));
        } else {
            mainApi.setUserInfo(email, name, localStorage.getItem("token"))
                .then((values) => {
                    setCurrentUser(values);
                    setErrorSignStatus(false);
                    setErrorSignText("Обновление данных успешно выполнено");
                })
                .catch((err) => {
                    setErrorSignStatus(true);
                    setErrorSignText(err);
                })
                .finally(() => setIsRenderLoading(false));
        }
    };

    const handleDateLike = (date) => {
        const isLiked = date.likes.some((i) => i === currentUser._id);
        mainApi.likeDate(date._id, isLiked, localStorage.getItem("token"))
            .then((newDate) => {
                setDates((state) =>
                    state.map((c) => (c._id === date._id ? newDate : c))
                );
            })
            .catch((err) => console.log(err));
    };

    const handleDateVisit = (date) => {
        const isVisited = date.visitors.some((i) => i === currentUser._id);
        mainApi.visitDate(date._id, isVisited, localStorage.getItem("token"))
            .then((newDate) => {
                setDates((state) =>
                    state.map((c) => (c._id === date._id ? newDate : c))
                );
            })
            .catch((err) => console.log(err));
    };

    const handleDateDislike = () => {
        mainApi.dislikeDate(dateToDislike._id, localStorage.getItem("token"))
            .then((newDate) => {
                setDates((state) =>
                    state.map((c) => (c._id === dateToDislike._id ? newDate : c))
                );
                setErrorPopupStatus(false);
                setErrorPopupText("Удаление успешно выполнено");
                closeAllPopups();
            })
            .catch((err) => {
                setErrorPopupStatus(true);
                setErrorPopupText(err);
            })
            .finally(() => setIsRenderLoading(false));
    };

    const handleDateUpdate = (date) => {
        mainApi.updateDate(date, localStorage.getItem("token"))
            .then((newDate) => {
                setDates((state) =>
                    state.map((c) => (c._id === dateToUpdate._id ? newDate : c))
                );
                setErrorPopupStatus(false);
                setErrorPopupText("Обновление успешно выполнено");
                closeAllPopups();
            })
            .catch((err) => {
                setErrorPopupStatus(true);
                setErrorPopupText(err);
            })
            .finally(() => setIsRenderLoading(false));
    };

    const handleDateCreate = (date) => {
        mainApi.createDate(date, localStorage.getItem("token"))
            .then((newDate) => {
                setDates([newDate, ...dates]);
                setErrorPopupStatus(false);
                setErrorPopupText("Создание успешно выполнено");
                closeAllPopups();
            })
            .catch((err) => {
                setErrorPopupStatus(true);
                setErrorPopupText(err);
            })
            .finally(() => setIsRenderLoading(false));
    };

    const handleDateDelete = () => {
        setIsRenderLoading(true);
        mainApi.deleteDate(dateToDelete._id, localStorage.getItem("token"))
            .then(() => {
                setDates((state) =>
                    state.filter((c) => c._id !== dateToDelete._id)
                );
                setErrorPopupStatus(false);
                setErrorPopupText("Удаление успешно выполнено");
                closeAllPopups();
            })
            .catch((err) => {
                setErrorPopupStatus(true);
                setErrorPopupText(err);
            })
            .finally(() => setIsRenderLoading(false));
    };

    React.useEffect(() => {
        if (isLoggedIn) {
            setIsRenderLoading(true);
            Promise.all([mainApi.getUserInfo(localStorage.getItem("token")), mainApi.getDates(localStorage.getItem("token"))])
                .then((values) => {
                    const [initialUser, initialDates] = values;
                    setCurrentUser(initialUser);
                    setDates(initialDates);
                    setErrorText("");
                })
                .catch((err) => {
                    setErrorText(err);
                    console.log(err);
                })
                .finally(() => setIsRenderLoading(false));
            }
    }, [isLoggedIn]);

    React.useEffect(() => {
        localStorage.getItem("token") &&
            handleSignCheck();
    }, [initialPath]);

    return (     
        <CurrentUserContext.Provider value={currentUser}> 
            <Switch>
                <UnprotectedRoute exact path="/" loggedIn={isLoggedIn} setInitialPath={setInitialPath}>
                    <Header loggedIn={isLoggedIn} path={"/sign-in"} buttonText={"Войти"} linkText={"Регистрация"} linkAddress={"/sign-up"}/>
                    <Name />
                    <Main />
                    <Footer loggedIn={isLoggedIn} /> 
                </UnprotectedRoute>
                <ProtectedRoute path="/dates" loggedIn={isLoggedIn} setInitialPath={setInitialPath}>
                    <Header loggedIn={isLoggedIn} handleClick={handleSignOut} buttonText={"Выйти"} linkText={currentUser.email} linkAddress={"/profile"}/>
                    <Name />
                    <SearchForm checkboxes={checkboxes} setCheckboxes={setCheckboxes} onSubmit={setFilterName} filterName={filterName} />
                    <SortForm onSubmit={setDateToCreate} sortType={sortType} onSort={setSortType}/>
                    {
                        isRenderLoading 
                        ? <Preloader />
                        : <Dates dates={filteredDates} onVisit={handleDateVisit} onLike={handleDateLike} onDislike={setDateToDislike} onDelete={setDateToDelete} onEdit={setDateToUpdate} sorryImage={sorryImage} text={errorText ? errorText : "Не найдено ни одной записи"}/>
                    } 
                    <SurePopup
                        date={dateToDelete}
                        onClose={closeAllPopups}
                        onSubmit={handleDateDelete}
                        onRenderLoading={isRenderLoading}
                        title="Удаление записи"
                        name="delete"
                        description="Внимание! Вы собираетесь удалить запись. Это действие не возможно отменить."
                        linkDescription="Передумали изменять запись?"
                        errorStatus={errorPopupStatus}
                        errorText={errorPopupText}
                    />
                    <SurePopup
                        date={dateToDislike}
                        onClose={closeAllPopups}
                        onSubmit={handleDateDislike}
                        onRenderLoading={isRenderLoading}
                        title="Скрыть запись"
                        name="dislike"
                        description="Внимание! Вы собираетесь скрыть запись. Это действие не возможно отменить."
                        linkDescription="Передумали скрывать запись?"
                        errorStatus={errorPopupStatus}
                        errorText={errorPopupText}
                    />
                    <EditPopup
                        date={dateToUpdate}
                        onClose={closeAllPopups}
                        onSubmit={handleDateUpdate}
                        onRenderLoading={isRenderLoading}
                        title="Редактирование записи"
                        name="edit"
                        linkDescription="Передумали редактировать запись?"
                        errorStatus={errorPopupStatus}
                        errorText={errorPopupText}
                    />
                    <EditPopup
                        date={dateToCreate}
                        onClose={closeAllPopups}
                        onSubmit={handleDateCreate}
                        onRenderLoading={isRenderLoading}
                        title="Редактирование записи"
                        name="create"
                        linkDescription="Передумали создавать запись?"
                        errorStatus={errorPopupStatus}
                        errorText={errorPopupText}
                    />
                    <Footer loggedIn={isLoggedIn} />
                </ProtectedRoute>
                <ProtectedRoute path="/profile" loggedIn={isLoggedIn} setInitialPath={setInitialPath}>
                    <Header loggedIn={isLoggedIn} handleClick={handleSignOut} buttonText={"Выйти"} linkText={"Главная"} linkAddress={"/"}/>
                    <Sign
                        name="update"
                        title={`Привет, ${currentUser.name}!`}
                        onSubmit={handleUpdateUser}
                        buttonText={
                            isRenderLoading 
                            ? "Обновление..." 
                            : "Обновить данные"
                        }
                        linkDescription="Хотите выйти из аккаунта?"
                        linkText="Выйти"
                        onLink={handleSignOut}
                        errorText={errorSignText}
                        errorStatus={errorSignStatus}
                        setErrorSignText={setErrorSignText}
                        setErrorSignStatus={setErrorSignStatus}
                        onRenderLoading={isRenderLoading}
                    />
                    <Footer loggedIn={isLoggedIn} />
                </ProtectedRoute>
                <UnprotectedRoute path="/sign-in" loggedIn={isLoggedIn} setInitialPath={setInitialPath}>
                    <Header loggedIn={isLoggedIn} path={"/sign-up"} buttonText={"Регистрация"} linkText={"Главная"} linkAddress={"/"}/>
                    <Sign
                        name="in"
                        title="Рады видеть!"
                        onSubmit={handleSignIn}
                        buttonText={
                            isRenderLoading 
                            ? "Вход..." 
                            : "Войти"
                        }
                        linkDescription="Ещё не зарегистрированы?"
                        linkText="Регистрация"
                        link="/sign-up"
                        errorText={errorSignText}
                        errorStatus={errorSignStatus}
                        setErrorSignText={setErrorSignText}
                        setErrorSignStatus={setErrorSignStatus}
                        isRenderLoading={isRenderLoading}
                    />
                    <Footer loggedIn={isLoggedIn} />
                </UnprotectedRoute>
                <UnprotectedRoute path="/sign-up" loggedIn={isLoggedIn} setInitialPath={setInitialPath}>
                    <Header loggedIn={isLoggedIn} path={"/sign-in"} buttonText={"Войти"} linkText={"Главная"} linkAddress={"/"}/>
                    <Sign
                        name="up"
                        title="Добро пожаловать!"
                        onSubmit={handleSignUp}
                        buttonText={
                            isRenderLoading
                                ? "Регистрация..."
                                : "Зарегистрироваться"
                        }
                        linkDescription="Уже зарегистрированы?"
                        linkText="Войти"
                        link="/sign-in"
                        errorText={errorSignText}
                        errorStatus={errorSignStatus}
                        setErrorSignText={setErrorSignText}
                        setErrorSignStatus={setErrorSignStatus}
                        isRenderLoading={isRenderLoading}
                    />
                    <Footer loggedIn={isLoggedIn} />
                </UnprotectedRoute>
                <Route>
                    {() => (isLoggedIn ? <Redirect to="/dates" /> : <Redirect to="/" />)}
                </Route>        
            </Switch>
        </CurrentUserContext.Provider>
    );
}

export default App;
