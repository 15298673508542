import image1 from "../images/sap.JPG"
import image2 from "../images/gallery.PNG"
import image3 from "../images/mountains.PNG"
import image4 from "../images/travel.PNG"
import image5 from "../images/park.JPG"
import image6 from "../images/aqua.PNG"

export const main = {
    name1: "Best ",
    name2: "Dates",
    description: "Добро пожаловать в ваш личный планировщик самых запоминающихся свиданий. Здесь вы сможете собрать свой собственный список мероприятий, которые смогут приятно удивить вашу вторую половинку. Сможете управлять ими и отмечать те из них, которые уже посетили и те которые больше всего понравились. Сможете знакомиться с удивительными событиями, которыми поделились другие пользователи и отмечать их для себя.",
    images: 
        [
            image1, image2, image3, image4, image5, image6,
        ],
    quote: "Не забывайте радовать себя и своих близких!",
    sign: "С наилучшими пожеланиями, Команда BestDates"
}
 