import React from "react";
import DateCard from "./DateCard";
import StatusText from "../StatusText/StatusText";

function Dates({ dates, text, onVisit, onLike, onDelete, onDislike, onEdit, sorryImage}) {

    return (
        <main className="dates">
            {
            dates.length === 0
            ? <StatusText text={text}/>
            : 
                (
                    dates.map((date, i) => (
                        <DateCard
                            key={date.id}
                            date={date}
                            onVisit={onVisit}
                            onLike={onLike}
                            onDelete={onDelete}
                            onDislike={onDislike}
                            onEdit={onEdit}
                            index={i}
                            sorryImage={sorryImage}
                        />
                    ))
                )
            }               
        </main>
       
    );
}

export default Dates;