import React from "react";
import { Link, useHistory } from "react-router-dom";

function Header({
    loggedIn,
    handleClick,
    path,
    buttonText,
    linkText,
    linkAddress
}) {
    const history = useHistory();

    function handlePush() {
        history.push(path)
    };
    
    return (
        <header className="header">
            <Link className={loggedIn ? `header__logo header__logo_login` : "header__logo"} to="/"/>
            <div className="header__navigation">
                    <Link to={linkAddress} className="header__link">{linkText}</Link>
                    <button
                        type="button"
                        className={loggedIn ? `header__button header__button_login` : "header__button"}
                        onClick={path ? handlePush : handleClick}
                    >
                    {buttonText}
                    </button>
            </div>
        </header>
    );
}

export default Header;
