import React from "react";
import { useFormAndValidation } from "../../hooks/useFormAndValidation";
import { datesTypes } from "../../consts/consts"
import { patterns } from "../../consts/patterns"

function SearchForm({ onSubmit, checkboxes, setCheckboxes }) {
    
    const { values, handleChange, isValid, resetForm, setIsValid } =
        useFormAndValidation();

    const [isSelectorActive, setIsSelectorActive] = React.useState(false);
    const [isAllSelected, setIsAllSelected] = React.useState(false);
    const [countSelected, setCountSelected] = React.useState(0);

    const typesLength = Object.keys(datesTypes).length;
    const selector = React.useRef();

    function handleSubmit(e) {
        e.preventDefault();
        isValid.name && onSubmit(values.name);
    }

    function handleCheckbox (e) {
        setCheckboxes({ ...checkboxes, [e.target.id]:!checkboxes[e.target.id]} );
    }

    function handleAllCheckbox () {
        setIsAllSelected(!isAllSelected)
        const obj = {}
        const array = Object.keys(datesTypes)
        array.forEach(element => {
            obj[element] = !isAllSelected;
        });
        setCheckboxes({ ...checkboxes, ...obj } )
    }

    function handleSetSelector () {
        setIsSelectorActive(!isSelectorActive)
    }

        React.useEffect(() => {
        function closeByEscape(e) {
            if (e.key === "Escape") {
                e.preventDefault();
                setIsSelectorActive(false);
            }
        }

        function closeByOuterClick(evt) {
            if (!evt.target.className.includes("search-form__checkbox") && !evt.target.className.includes("search-form__select")) {
                setIsSelectorActive(false);
            }
        }

        if (isSelectorActive) {
            document.addEventListener("keydown", closeByEscape);
            document.addEventListener("click", closeByOuterClick);
            return () => {
                document.removeEventListener("keydown", closeByEscape);
                document.removeEventListener("click", closeByOuterClick);
            };
        }
    }, [isSelectorActive]);

    React.useEffect(() => {
        var i = 0;
        Object.keys(datesTypes).forEach((element) => {
            checkboxes[element] && i++
        });
        setCountSelected(i);
    }, [checkboxes]);

    React.useEffect(() => {
        (countSelected === typesLength) ? setIsAllSelected(true) : setIsAllSelected(false);
    }, [countSelected]);

   
    React.useEffect(() => {
        resetForm(
            { name: "" },
            { name: "" },
            { name: true }
        );
    }, []);

    return (
        <>
            <form className="search-form" id="search-form" name ="search-form" onSubmit={handleSubmit}>            
                <div className="search-form__selectors">
                    <fieldset className="search-form__inputs">
                        <div className="search-form__icon"></div>
                        <input
                            type="text"
                            className={
                                    isValid.name
                                    ? "search-form__input"
                                    : "search-form__input search-form__input_active"
                                }
                            id={`search-form-name`}
                            name="name"
                            onChange={handleChange}
                            placeholder={isValid.name ? "Поиск" : "Поиск"}
                            pattern={patterns.namePattern}
                            value={values.name ? values.name : ""}
                        />
                        <input
                            type="submit"
                            className="search-form__button"                        
                            id={`search-form__button`}
                            value=""
                            disabled={!isValid.name}
                        />
                    </fieldset>
                    <div className="search-form__select">
                        <div className="search-form__select-container" onClick={handleSetSelector}>
                            <p className={(countSelected ===0) ? "search-form__select-container-text search-form__select-container-text_active" : "search-form__select-container-text"}>Жанры: {isAllSelected ? "Все" : countSelected + "/" + typesLength}</p>
                            <div className={isSelectorActive ? "search-form__select-container-icon_down search-form__select-container-icon_up" : "search-form__select-container-icon_down"}></div>
                        </div>    
                        <div ref={selector} className={isSelectorActive ? "search-form__checkboxes-container search-form__checkboxes-container_active" : "search-form__checkboxes-container"}>
                            <div className="search-form__checkbox-container">
                                <button className={ isAllSelected ? "search-form__checkbox" : "search-form__checkbox search-form__checkbox_off"} type="button" id="all" onClick={handleAllCheckbox}></button>
                                <p className={ isAllSelected ? "search-form__checkbox-name" : "search-form__checkbox-name search-form__checkbox-name_off"}>Выбрать все</p>
                            </div>
                            {Object.keys(datesTypes).map((date, i) => 
                                <div className="search-form__checkbox-container">
                                    <button className={ checkboxes[date] ? "search-form__checkbox" : "search-form__checkbox search-form__checkbox_off"} type="button" id={date} onClick={handleCheckbox}></button>
                                    <p className={ checkboxes[date] ? "search-form__checkbox-name" : "search-form__checkbox-name search-form__checkbox-name_off"}>{datesTypes[date]}</p>         
                                </div>    
                            )}
                        </div> 
                    </div>
                </div>
                <div className="search-form__tumbler-containers">             
                    <div className="search-form__tumbler-container">
                        <button className={ checkboxes.liked ? "search-form__tumbler" : "search-form__tumbler search-form__tumbler_off"} type="button" id="liked" onClick={handleCheckbox}></button>
                        <p className="search-form__tumbler-name">Нравится</p>
                    </div>  
                    <div className="search-form__tumbler-container">
                        <button className={ checkboxes.visited ? "search-form__tumbler" : "search-form__tumbler search-form__tumbler_off"} type="button" id="visited" onClick={handleCheckbox}></button>
                        <p className="search-form__tumbler-name">Посещено</p>
                    </div>  
                    <div className="search-form__tumbler-container">
                        <button className={ checkboxes.mine ? "search-form__tumbler" : "search-form__tumbler search-form__tumbler_off"} type="button" id="mine" onClick={handleCheckbox}></button>
                        <p className="search-form__tumbler-name">Только мои</p>
                    </div>
                </div>              
            </form>
        </>
    );
}

export default SearchForm;