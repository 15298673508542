import React from "react";
import { datesTypes, datesInitialTypes } from "../consts/consts"

export function useDatesFilter() {
    const [checkboxes, setCheckboxes] = React.useState({});
    const [dates, setDates] = React.useState([]);
    const [filteredDates, setFilteredDates] = React.useState(dates);
    const [filterName, setFilterName] = React.useState("");
    const [currentUser, setCurrentUser] = React.useState({
        name: "",
        email: "",
    });
    const [sortType, setSortType] = React.useState('create');

    function initCheckboxes() {
        const obj = {}
        const array = Object.keys(datesTypes).concat(datesInitialTypes)
        array.forEach((type) =>  {
            obj[type] = false
        });
        setCheckboxes(obj)
    }
    
    const handleFilter = (array, filter, checkboxes, sortType) => {
        
        let result = array.filter(
            (date) => 
                !date.dislikes 
                    .includes(currentUser._id)
        )
        
        result = result.filter(
            (date) =>
                date.name
                    .toLowerCase()
                    .includes(filter.toLowerCase())
        );
        Object.keys(checkboxes).forEach((type) => 
            {
                if (checkboxes[type] && !datesInitialTypes.includes(type))  {
                    result = result.filter (
                        (date) =>
                            date.types
                                .includes(datesTypes[type])
                    )
                }
                if (checkboxes[type] && type === 'mine')  {
                    result = result.filter (
                        (date) => 
                            date.owner === currentUser._id
                    )
                }  
                if (checkboxes[type] && type === 'visited')  {
                    result = result.filter (
                        (date) => 
                            date.visitors 
                                .includes(currentUser._id)
                    )
                }  
                if (checkboxes[type] && type === 'liked')  {
                    result = result.filter (
                        (date) => 
                            date.likes 
                                .includes(currentUser._id)
                    )
                }                 
            }           
        )
        if (sortType === 'create') {
            result = [...result].sort((a, b) =>
                a.createdAt > b.createdAt ? 1 : -1,
            );
        }
        if (sortType === 'name') {
            result = [...result].sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
            );
        }
        if (sortType === 'author') {
            result = [...result].sort((a, b) =>
                a.author > b.author ? 1 : -1,
            );
        }
        if (sortType === 'cost') {
            result = [...result].sort((a, b) =>
                a.price > b.price ? 1 : -1,
            );
        }
        return result;
    };
    
    React.useEffect(() => {
        initCheckboxes();
    }, []);

    React.useEffect(() => {
        setFilteredDates(handleFilter(dates, filterName, checkboxes, sortType));
    }, [dates, filterName, checkboxes, currentUser, sortType]);


    return {
        checkboxes,
        setCheckboxes,
        filteredDates,
        filterName,
        setFilterName,
        dates,
        setDates,
        currentUser, 
        setCurrentUser,
        sortType, 
        setSortType,
    };
}
