import { apiURL } from "../consts/links";

class Api {
    constructor({ baseUrl }) {
        this._baseUrl = baseUrl;
    }

    #isOK(res) {
        const status = res.ok;
        const statusMessage = res.status;
        return res.json().then((data) => {
            if (!status) {
                return Promise.reject(
                    `Ошибка: ${statusMessage}; ${data.message}`
                );
            }
            return data;
        });
    }

    _doRequest(url, options) {
        return fetch(url, options).then(this.#isOK);
    }

    getUserInfo(token) {
        return this._doRequest(`${this._baseUrl}/users/me`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`
            },
        });
    }

    getDates(token) {
        return this._doRequest(`${this._baseUrl}/dates`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`
            },
        });
    }


    setUserInfo(email, name, token, password) {
        return this._doRequest(`${this._baseUrl}/users/me`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                name: name,
                email: email,
                password: password,
            }),
        });
    }

    likeDate(dateId, isLiked, token) {
        return this._doRequest(`${this._baseUrl}/dates/${dateId}/like`, {
            method: !isLiked ? "PUT" : "DELETE",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`
            },
        });
    }

    visitDate(dateId, isVisited, token) {
        return this._doRequest(`${this._baseUrl}/dates/${dateId}/visit`, {
            method: !isVisited ? "PUT" : "DELETE",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`
            },
        });
    }

    dislikeDate(dateId, token) {
        return this._doRequest(`${this._baseUrl}/dates/${dateId}/dislike`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`
            },
        });
    }

    deleteDate(dateId, token) {
        return this._doRequest(`${this._baseUrl}/dates/${dateId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`
            },
        });
    }

    updateDate(date, token) {
        return this._doRequest(`${this._baseUrl}/dates/${date._id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`
            },
            body: JSON.stringify(date),
        });
    }

    createDate(date, token) {
        return this._doRequest(`${this._baseUrl}/dates`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`
            },
            body: JSON.stringify(date),
        });
    }

    signIn(password, email) {
        return this._doRequest(`${this._baseUrl}/signin`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: password,
                email: email,
            }),
        });
    }

    signUp (password, email, name, code) {
        return this._doRequest(`${this._baseUrl}/signup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: password,
                email: email,
                name: name,
                code: code
            }),
        });
    }

}

export const mainApi = new Api({
    baseUrl: apiURL.main,
});
