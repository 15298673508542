import Popup from "./Popup";
import React from "react";

function SurePopup({ onRenderLoading, date, onClose, onSubmit, title, name, description, linkDescription, errorStatus, errorText }) {
    function handleSubmit(e) {
        e.preventDefault();
        onSubmit();
    }

    return (
        <Popup
            title={title}
            name={name}
            buttonText={onRenderLoading ? "Удаление..." : "Подтвердить"}
            isOpened={date._id ? true : false}
            onClose={onClose}
            onSubmit={handleSubmit}
            buttonState={true}
            linkDescription={linkDescription}
            errorStatus={errorStatus}
            errorText={errorText}
        >
            <p className="popup__description">{description}</p>
        </Popup>
    );
}

export default SurePopup;
