import React from "react";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";

function DateCard({ date, onVisit, onLike, onDelete, onDislike, onEdit, sorryImage, index}) {  

    const currentUser = React.useContext(CurrentUserContext);
    const isOwn = date.owner === currentUser._id;
    const isLiked = date.likes.some((i) => i === currentUser._id);
    const isDisliked = date.dislikes.some((i) => i === currentUser._id);  
    const isVisited = date.visitors.some((i) => i === currentUser._id);     
    
    function handleLikeClick() {
        onLike(date);
    }

    function handleDislikeClick() {
        onDislike(date);
    }

    function handleDeleteClick() {
        onDelete(date);
    }

    function handleVisitClick() {
        onVisit(date);
    }
    
    function handleEditClick() {
        onEdit(date);
    }

    return (
    !isDisliked &&
        <div className={index % 2 === 0 ? "date" : "date date_reverse"} id={date._id}>
            {isVisited && <div className={index % 2 === 0 ? "date__image-watermark date__image-watermark_left" : "date__image-watermark"}></div>}
            <img
                className="date__image"
                src={date.image ? date.image : sorryImage}
                alt={date.name}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = sorryImage;
                }}
            />
            <div className="date__buttons">
                <button className={ !isVisited ? "date__button date__button_visit" : "date__button date__button_visit date__button_visit_on"} type="button" id="visit" onClick={handleVisitClick}></button>
                <button className={ !isLiked ? "date__button date__button_like" : "date__button date__button_like date__button_like_on"} type="button" id="like" onClick={handleLikeClick}></button>
                <button className="date__button date__button_delete" type="button" id="like" onClick={!isOwn ? handleDislikeClick : handleDeleteClick}></button>
                {isOwn && <button className="date__button date__button_edit" type="button" id="like" onClick={handleEditClick}></button>}
            </div>
            <div className={!isVisited ? "date__info" : "date__info date__info_visited"}>
                <p className="date__name">{date.name}</p>
                <p className={date.address ? (date.link ? "date__description" : "date__description date__description_middle") : (date.link ? "date__description date__description_middle" : "date__description date__description_large")}>{date.description}</p>
                {
                    date.address &&
                        <div className="date__text-item">
                            <p className="date__text-item-name">Адреc: </p>
                            <p className="date__text-item-value">{date.address}</p>
                        </div>
                }
                {
                    date.link &&
                        <div className="date__text-item">
                            <p className="date__text-item-name">Ссылка: </p>
                            <a className="date__text-item-link" href={date.link}>{date.link}</a>
                        </div>
                }
                <div className="date__text-items">
                    {
                        date.price &&
                            <div className="date__text-item date__text-item_half">
                                <p className="date__text-item-name">Стоимость: </p>
                                <p className="date__text-item-value">{date.price}</p>
                            </div>
                    }
                    <div className="date__text-item date__text-item_half">
                        <p className="date__text-item-name">Автор: </p>
                        <p className="date__text-item-value">{date.author}</p>
                    </div>
                </div>
                <div className="date__tags">
                    {
                    date.types.map((tag) => (
                        <p className={!isVisited ? "date__tag" : "date__tag date__tag_visited"}>{tag}</p>
                    ))
                    }
                </div>
            </div>
        </div>
    );
}

export default DateCard;