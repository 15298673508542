import React from "react";

function Footer() {    

    return (
        <div className="footer">
            <p className="footer__tittle">Сайт создан энтузиастами и не осуществляет никакой комерческой деятельности</p>
            <div className="footer__info">
                <p className="footer__year">&copy; {new Date().getFullYear()}</p>
                <div className="footer__links">
                    <a className="footer__link" href="https://vk.com/">Мы в Вконтакте</a>
                    <a className="footer__link" href="https://web.telegram.org/">Мы в Telegram</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;