import React from "react";
import { main } from "../../consts/texts" 

function Name() {
    
    return (
        <h1 className="name">
            <p className="name__title">{main.name1}</p>
             <p className="name__title name__title_second">{main.name2}</p>
        </h1>
    );
}

export default Name;