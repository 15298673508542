import React from "react";
import { main } from "../../consts/texts" 

function Main() {
    
    return (
        <main className="main">
            <p className="main__description">{main.description}</p>
            <div className="main__section">
                <div className="main__grid">
                    {main.images.map((image, i) => 
                        <img key={i} className="main__grid-image" src={image}/>              
                    )}
                </div>
                <div className="main__epigraph">
                    <p className="main__quote">{main.quote}</p>
                    <p className="main__sign">{main.sign}</p>
                </div>
            </div>
        </main>       
    );
}

export default Main;