import React from "react";

function SortForm({ onSubmit, sortType, onSort }) {
   
   function changeValue(e) {
      onSort(e.target.attributes.value.value);
   }

    function handleSubmit(e) {
        e.preventDefault();
        onSubmit({_id:"new", types:[]});
    }

    return (
        <>
            <form className="sort-form" id="sort-form" name ="sort-form">            
                <fieldset className="sort-form__inputs">
                    <div className="sort-form__starter">
                        <div className="sort-form__icon"></div>
                        <p className="sort-form__text">Упорядочить по:</p>
                    </div>
                    <div className="sort-form__inputs-container">
                        <div className="sort-form__radio-container" onClick={changeValue} value="create">
                            <div className={ sortType === 'create' ? "sort-form__radio sort-form__radio_active" : "sort-form__radio"} onClick={changeValue} value="create"/>
                            <p className="sort-form__radio-name" onClick={changeValue} value="create">Созданию</p>
                        </div>
                        <div className="sort-form__radio-container" onClick={changeValue} value="name">
                            <div className={sortType === 'name' ? "sort-form__radio sort-form__radio_active" : "sort-form__radio"} onClick={changeValue} value="name"/>
                            <p className="sort-form__radio-name" onClick={changeValue} value="name">Названию</p>                            
                        </div>
                        <div className="sort-form__radio-container" onClick={changeValue} value="author">
                            <div className={sortType === 'author' ? "sort-form__radio sort-form__radio_active" : "sort-form__radio"} onClick={changeValue} value="author"/>
                            <p className="sort-form__radio-name" onClick={changeValue} value="author">Автору</p>                                        
                        </div>
                        <div className="sort-form__radio-container" onClick={changeValue} value="cost">
                            <div className={sortType === 'cost' ? "sort-form__radio sort-form__radio_active" : "sort-form__radio"} onClick={changeValue} value="cost"/>
                            <p className="sort-form__radio-name" onClick={changeValue} value="cost">Стоимости</p>                                       
                        </div>
                    </div>
                </fieldset>
                <button className="sort-form__button" type="button" id="create" onClick={handleSubmit}>Создать новую запись</button>     
            </form>
        </>
    );
}

export default SortForm;